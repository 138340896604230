<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20 clearfix" ref="searchForm" :model="searchForm">
      <!-- <FormItem prop="product_model_code">
        <span>产品编号：</span>
        <Select class="iviewIptWidth250" v-model="searchForm.product_model_code">
          <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
      </FormItem> -->
      <FormItem prop="order_number">
        <span>订单号：</span>
        <i-input placeholder="请输入" class="iviewIptWidth165" v-model="searchForm.order_number"></i-input>
      </FormItem>
      <FormItem prop="order_number">
        <span>订单时间：</span>
        <DatePicker type="daterange" format="yyyy-MM-dd" placeholder="请选择" class="iviewIptWidth250" @on-change="changeTime"></DatePicker>
      </FormItem>
      <FormItem prop="product_id">
        <span>产品名称：</span>
        <Select class="iviewIptWidth250" v-model="searchForm.product_id" @on-change="getSKUSelect" clearable filterable>
          <Option v-for="(item, index) in productSelect" :value="item.product_id" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem prop="model_name">
        <span>规格型号/SKU：</span>
        <Select class="iviewIptWidth250" placeholder="请选择" v-model="searchForm.model_name" multiple :max-tag-count="1" filterable :disabled="SKUDisable">
          <Option v-for="(item, index) in SKUSelect" :value="item.model_name" :key="index">{{ item.model_name }}/{{ item.item_number }}</Option>
        </Select>
      </FormItem>
      <FormItem class="po-create-number marginRight0" :label-width="10">
        <span class="pageBtn finger btnSure" @click="searchDetailList">查询</span>
        <span class="pageBtn finger btnAdd marginLeft20" @click="output">导出</span>
      </FormItem>
    </Form>
    <Table :productList="listColumns" :productData="listData" :total="total" :pages="pages" @change-page="changePage" :isLoad="isLoad" totalText="条记录">
      <div slot="summary" class="summary">
        <div>
          <span style="margin-right: 20px">总数量：{{ storage_totals }}</span>
          <span>合计金额：</span>
          <span class="color389">¥{{ storage_amounts }}</span>
        </div>
      </div>
    </Table>
  </div>
</template>

<script>
import Table from '@/components/table'
export default {
  components: {
    Table
  },
  computed: {
    kucun() {
      let totle = 0
      this.listData.forEach((e, i) => {
        totle = totle * 1 + e.storage_total * 1
      })
      return totle
    },
    totleMoney() {
      let totle = 0
      this.listData.forEach((e, i) => {
        totle = totle * 1 + e.storage_amount * 1
      })
      return totle
    }
  },
  data() {
    return {
      cityList: [
        {
          value: 'New York',
          label: 'New York'
        },
        {
          value: 'London',
          label: 'London'
        }
      ],
      isLoad: false,
      no: '',
      time: '',
      /* 表格表头 */
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 75,
          minWidth: 75
        },
        {
          title: '订单号',
          key: 'order_number',
          align: 'center',
          minWidth: 120
        },
        {
          title: '产品编号',
          key: 'product_model_code',
          align: 'center',
          minWidth: 120
        },
        {
          title: '产品名称',
          key: 'name',
          align: 'center',
          minWidth: 220
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          minWidth: 100
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          minWidth: 100
        },
        {
          title: '发货数量',
          key: 'ship_total',
          align: 'center',
          minWidth: 80,
          maxWidth: 120
        },
        {
          title: '结算数量',
          key: 'settlement_total',
          align: 'center',
          minWidth: 80,
          maxWidth: 120
        },
        {
          title: '库存数量',
          key: 'storage_total',
          align: 'center',
          minWidth: 80,
          maxWidth: 120
        },
        {
          title: '库存金额',
          align: 'center',
          render: (h, param) => {
            return h('div', [h('span', '¥' + param.row.storage_amount)])
          },
          minWidth: 110,
          maxWidth: 160
        }
      ],
      /* 表格数据 */
      listData: [
        {
          code: '567890',
          name: '药物涂层冠脉球囊导管',
          guige: 'PA-35-150',
          sku: 'PA-35-150',
          fahuo: '20',
          jiesuan: '20',
          kucun: '30',
          money: 1000.0
        },
        {
          code: '567890',
          name: '药物涂层冠脉球囊导管',
          guige: 'PA-35-150',
          sku: 'PA-35-150',
          fahuo: '20',
          jiesuan: '20',
          kucun: '30',
          money: 1000.0
        }
      ],
      total: 0,
      storage_amounts: 0, // 合计金额
      storage_totals: 0, // 合计数量
      pages: {
        page: 1,
        rows: 10
      },
      // 搜索表格字段
      searchForm: {
        product_id: '', // 产品id
        model_name: [] // 规格型号(支持多选）列表传参
      },
      searchObj: {}, // 搜索对象
      productSelect: [], // 产品名称下拉列表
      SKUSelect: [], // 规格型号下拉列表
      SKUDisable: true // SKU下拉禁用
    }
  },
  methods: {
    changeTime(e) {
      console.log(e)
      this.searchForm.order_time_start = e[0]
      this.searchForm.order_time_end = e[1]
      console.log(this.searchForm)
    },
    goDetails(type, id) {
      this.$router.push({
        path: '/billingDetails',
        query: {
          type: type,
          id: type == 2 ? id : ''
        }
      })
    },
    changePage(e) {
      this.pages.page = e
      let searchObj = this.searchObj
      this.getDetailList(this.pages, searchObj)
    },
    /* 获取业务明细单 */
    async getDetailList(pages, item) {
      let obj = {}
      Object.assign(obj, pages, item)
      let res = await this.$http.get(this.$api.getBusinessDetail, obj, true)
      if (res.status) {
        this.listData = res.data.rows
        this.total = res.data.total
        this.storage_amounts = res.data.storage_amounts
        this.storage_totals = res.data.storage_totals
      }
    },
    /* 搜索业务明细单 */
    searchDetailList() {
      let name = null
      if (this.searchForm.product_id) {
        let product_id = this.searchForm.product_id
        let product = this.productSelect.find(item => {
          return item.product_id === product_id
        })
        name = product.name
      }
      let model_name = []
      for (const item of this.searchForm.model_name) {
        model_name.push(item)
      }
      let order_number = this.searchForm.order_number
      let order_time_start = this.searchForm.order_time_start
      let order_time_end = this.searchForm.order_time_end

      let item = { name, model_name, order_number, order_time_start, order_time_end }
      this.searchObj = item
      this.pages = { page: 1, rows: 10 }
      this.getDetailList(this.pages, item)
    },
    /* 获取供应商的产品下拉列表 */
    getProductSelect() {
      this.$http.get(this.$api.supplierProductSelect).then(res => {
        if (res.status) {
          this.productSelect = res.data
        }
      })
    },
    /* 获取供应商下所有规格型号下拉选择 */
    getSKUSelect(pid) {
      if (pid) {
        this.SKUSelect = []
        this.$set(this.searchForm, 'model_name', [])
        let product_id = pid
        this.SKUDisable = false
        this.$http.get(this.$api.SKUSelect, { product_id }).then(res => {
          if (res.status) {
            this.SKUSelect = res.data
          }
        })
      } else {
        this.SKUDisable = true
        this.searchForm.model_name = []
        this.SKUSelect = []
      }
    },
    // 导出
    output() {
      this.$Message.warning('功能开发中...')
    }
  },
  created() {
    this.getDetailList(this.pages, {})
    this.getProductSelect()
  }
}
</script>

<style scoped lang="less">
.summary {
  height: 48px;
  line-height: 48px;
  border: 1px solid #e8eaec;
  border-top: none;
  text-align: right;
  padding-right: 23px;
}
</style>
